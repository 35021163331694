<template>
  <span class="certificate-uploader">
    <div v-for="fileIndex of fileIndexes" :key="fileIndex">
      <ion-row>
        <ion-col>
          <input type="file" :id="'file' + fileIndex" :ref="'file' + fileIndex" @change="onFileUpload(fileIndex)" />
        </ion-col>
      </ion-row>
      <div v-if="(fileIndex < files.length)">
        <ion-row>
          <ion-col size="6" class="file-detail">
            <div v-if="files[fileIndex]">Date: {{ lastModifiedStr(fileIndex) }}</div>
            <div v-else class="warning-text">Veuillez saisir un fichier</div>
          </ion-col>
          <ion-col size="4" class="file-detail text-center">
            <div v-if="files[fileIndex]">Taille: {{ fileSizeStr(fileIndex) }}</div>
          </ion-col>
          <ion-col size="2" class="text-right">
            <a href @click.prevent="clearFile(fileIndex)">
              <i class="fa fa-window-close"></i>
            </a>
          </ion-col>
        </ion-row>
        <ion-row
          v-if="files[fileIndex] && files[fileIndex].size >= maxSize"
          class="error-text"
        >
          <ion-col>
            Le fichier dépasse la limite autorisée de {{ maxSizeStr }}
          </ion-col>
        </ion-row>
      </div>
    </div>
    <div v-if="this.showExtraFiles">
      <ion-row>
        <ion-col>
          <div v-if="files.length < 10">
            <a href @click.prevent="addExtraFile">
              <i class="fa fa-plus-circle"></i> Ajouter un fichier complémentaire
            </a>
          </div>
          <div v-else>
            <i class="fa fa-exclamation-triangle"></i> Nombre maximum de fichiers atteint
          </div>
        </ion-col>
      </ion-row>
    </div>
    <ion-row>
      <ion-col>
        <div><b>Commentaire</b></div>
        <ion-textarea v-model="comments" placeholder="Une précision? Laissez-nous un message"></ion-textarea>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="text-right" size="4" size-xs="12">
        <ion-button size="small" @click.prevent="onUpload" :class="{ disabled: !isUploadValid(), }">
          <i class="fa fa-upload"></i> Envoyer
        </ion-button>
      </ion-col>
    </ion-row>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import {
  IonCol, IonRow, IonButton, IonTextarea
} from '@ionic/vue'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { fileSize, tsToDate } from '@/filters/texts'

export default {
  name: 'certificate-uploader',
  components: { IonRow, IonCol, IonButton, IonTextarea, },
  mixins: [BackendMixin],
  props: {
    certificateRequest: Object,
    entity: Object,
  },
  emits: ['uploaded'],
  data() {
    return {
      errorText: '',
      files: [],
      maxSize: 1024 * 1024,
      comments: '',
    }
  },
  computed: {
    maxSizeStr() {
      return fileSize(this.maxSize)
    },
    fileIndexes() {
      if (this.files.length === 0) {
        return [0]
      } else {
        return this.files.map((elt, index) => index)
      }
    },
    showExtraFiles(){
      if (this.files.length > 0) {
        return this.files[this.files.length - 1] !== null
      }
      return false
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    isUploadValid() {
      if (this.files.length === 0) {
        return false
      }
      for (const file of this.files) {
        if (!file) {
          return false
        }
        if (file.size >= this.maxSize) {
          return false
        }
      }
      return true
    },
    fileSizeStr(fileIndex) {
      return fileSize(this.files[fileIndex].size)
    },
    lastModifiedStr(fileIndex) {
      return tsToDate(this.files[fileIndex].lastModified)
    },
    async onUpload() {
      this.errorText = ''
      if (this.isUploadValid()) {
        try {
          const entityId = this.entity.id
          const individualId = this.certificateRequest.individual.id
          const schoolYearId = this.certificateRequest.schoolYear.id
          const certificateTypeId = this.certificateRequest.certificateType.id
          const args = [entityId, schoolYearId, certificateTypeId, individualId].map(
            elt => ('' + elt)
          ).join('/')
          const url = '/portal/api/upload-certificate/' + args + '/'
          const backendApi = new BackendApi('post', url)
          const data = new FormData()
          for (let fileIndex = 0; fileIndex < this.files.length; fileIndex++) {
            data.append('file' + fileIndex, this.files[fileIndex])
          }
          data.append('files_count', '' + this.files.length)
          data.append('comments', this.comments)
          await backendApi.callApi(data, 'multipart/form-data')
          this.$emit('uploaded')
          this.addSuccess('Le document a été envoyé. Il va être vérifié par notre équipe')
        } catch (err) {
          this.errorText = this.getErrorText(err)
          this.addError(this.getErrorText(err))
        }
      }
    },
    onFileUpload(fileIndex) {
      if (fileIndex <= this.files.length) {
        this.files[fileIndex] = this.$refs['file' + fileIndex].files[0]
      }
    },
    addExtraFile() {
      if (this.files.length <= 10) {
        this.files.push(null)
      }
    },
    clearFile(fileIndex){
      if (fileIndex < this.files.length) {
        this.files.splice(fileIndex, 1)
      }
    },
  },
}
</script>
<style scoped>
  .error-text {
    padding: 2px;
    color: #e0e0e0;
    background: #cc3700;
  }
  .warning-text {
    font-weight: bold;
  }
  .the-title {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: solid 1px #ccc;
    font-weight: bold;
  }
  .file-detail {
    font-size: 12px;
  }
  .certificate-uploader {
    display: block;
    margin: 5px 0;
    padding: 5px;
    border: solid 1px #ccc;
    border-radius: 4px;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  ion-textarea {
    border: solid 1px #ccc;
  }
</style>
