
import { defineComponent, ref } from "vue"
import {
  IonPage, IonHeader, IonToolbar, IonContent, IonRow, IonCol, IonButton, IonCard, IonCardContent
} from '@ionic/vue'
import EntitySelect from '@/components/EntitySelect.vue'
import TopBar from '@/components/TopBar.vue'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import MessageBar from '@/components/Controls/MessageBar.vue'
import store from '@/store'
import { loadCertificateRequests } from '@/apis/certificates'
import { Entity, makeEntity } from '@/types/people'
import { CertificateDocument, CertificateRequest } from '@/types/certificates'
import CertificateUploader from '@/components/CertificateUploader.vue'
import { getErrorText, openDocument } from '@/utils/http'

export default defineComponent({
  name: 'CertificatesTab',
  components: {
    CertificateUploader,
    LoadingGif, EntitySelect, TopBar, MessageBar,
    IonHeader, IonToolbar, IonContent, IonPage, IonRow, IonCol, IonButton, IonCard, IonCardContent,
  },
  setup() {
    const loadingName = 'certificates-list'
    const entity = ref(makeEntity())
    const initialElements: CertificateRequest[] = []
    const certificateRequests = ref(initialElements)

    async function getCertificateRequests(entity: Entity) {
      if (entity && entity.id) {
        store.commit('startLoading', loadingName)
        try {
          const elements: CertificateRequest[] = await loadCertificateRequests(entity)
          certificateRequests.value = elements
          store.commit('setCertificateRequests', certificateRequests.value)
        } catch (err) {
          await store.dispatch('addError', getErrorText(err))
        }
        store.commit('endLoading', loadingName)
      }
    }

    function onEntityChanged(newEntity: Entity) {
      const effectiveChange = (entity.value.id !== newEntity.id)
      entity.value = newEntity
      if (effectiveChange) {
        getCertificateRequests(newEntity)
      }
    }

    function getStatusClass(certificateRequest: CertificateRequest) {
      if (certificateRequest.certificate === null) {
        if (certificateRequest.certificateType.isRequired) {
          return 'required-document'
        } else {
          return 'optional-document'
        }
      } else if (certificateRequest.certificate.verifiedOn !== null) {
        if (certificateRequest.certificate.refused) {
          return 'refused-document'
        } else {
          return 'verified-document'
        }
      } else {
        return 'waiting-document'
      }
    }

    function refresh() {
      getCertificateRequests(entity.value)
    }

    function isLoading(): boolean {
      return store.getters.loading && store.getters.loading[loadingName]
    }

    async function onViewDocument(document: CertificateDocument) {
      const docUrl = '/portal/download-certificate-document/<key>/'
      const docSlug = '' + document.id
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await store.dispatch('addError', getErrorText(err))
      }
    }

    return {
      certificateRequests, entity, loadingName, onEntityChanged, refresh, isLoading, getStatusClass,
      onViewDocument,
    }
  },
})
